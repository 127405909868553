<template>
  <v-dialog v-model="dialog" :width="width" :persistent="persistent">
    <v-card>
      <v-card-title :class="`headline ${headlineClass}`">
        {{ title }}
      </v-card-title>
      <v-layout class="px-10 py-10">
        <v-row class="ma-0">
          <span>{{ message }}</span>
        </v-row>
      </v-layout>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="cancelButton"
          small
          text
          color="error"
          class="rounded-pill px-3"
          @click.stop="onCancel()">{{ cancelButtonText }}</v-btn>
        <v-btn
          v-if="confirmButton"
          small
          :class="`${confirmBtnClass} rounded-pill px-5`"
          @click.stop="onConfirmation()">{{ confirmButtonText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Confirmation",
    },
    width: {
      type: Number,
      default: 500,
    },
    message: {
      type: String,
      default: "Are you sure?",
    },
    confirmButton: {
      type: Boolean,
      default: true,
    },
    confirmButtonText: {
      type: String,
      default: "Confirm",
    },
    cancelButtonText: {
      type: String,
      default: "Cancel",
    },
    cancelButton: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    headlineClass: {
      type: String,
      default: "primary white--text",
    },
    confirmBtnClass: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onConfirmation() {
      this.$emit("onConfirmation");
    },
    onCancel() {
      this.$emit("onCancel");
    },
  },
};
</script>
