<template>
  <v-stepper class="bg-none pb-2 pt-1" non-linear>
    <v-stepper-header style="height:30px">
      <div v-for="item in stepper" :key="item.val">
        <v-stepper-step
          class="px-0 py-0"
          :step="item.val"
          :complete="item.val<=success?true:false"
        >
        {{ item.text }}
        </v-stepper-step>
      </div>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: {
    stepper: {
      type: Array,
      default: [],
    },
    success: {
      type: Number,
      default: "upload",
    }
  }
};
</script>