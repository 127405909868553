<template>
  <div>
    <v-row class="mt-0">
      <!-- upload form -->
      <v-col cols="12" sm="2">
        <h4 class="pt-5">UPLOAD METHODS</h4>
      </v-col>
      <v-col v-if="isClient && (status=='requested'||status=='created')" cols="12" sm="6">
        <div v-if="list.length>0" class="mt-4">
          <v-switch v-model="isUploadMore" @change="uploadMore" hide-details label="Upload more image"></v-switch>
        </div>
        <div v-if="list.length<=0 || isUploadMore">
          <v-radio-group v-model="upload_method" @change="onChange" row>
            <v-radio label="UPLOAD VIA THIS WEBSITE" :value=1></v-radio>
            <v-radio label="UPLOAD VIA FTP" :value=2></v-radio>
          </v-radio-group>

          <v-card class="gray-border">
            <v-card-text v-if="upload_method==1">
              <div class="error--text pb-4 text-center">Use FTP if each file size > 10MB</div>
              <div>
                <v-btn small :disabled="isBrowsed" color="primary" @click="$refs.uploader.click()">
                  BROWSE
                </v-btn>
                <input multiple ref="uploader" class="d-none" type="file" accept="image/*"
                  @change="selectFiles($event.target.files)"
                >
                <v-btn small text color="error" class="float-right" @click="onCancel()">
                  CANCEL
                </v-btn>
              </div>

              <div class="mb-4">
                <v-textarea disabled auto-grow outlined hide-details class="my-5" rows="3" :value="filetxt"></v-textarea>
                <v-btn small color="warning" :disabled="!onUpload" :loading="loadingBtn" @click.stop="uploadviaweb">
                  Upload
                  <v-icon right dark>{{mdiCloudUpload}}</v-icon>
                </v-btn>
              </div>

              <div v-if="progressInfos.length>0" v-for="(info, inx) in progressInfos" :key="inx">
                <v-progress-linear
                  :color="info.percentage>0 ? 'primary' : 'error'"
                  buffer-value="0"
                  :value="info.percentage"
                  stream
                ></v-progress-linear>
                <p class="text-center mb-2">{{ info.fileName }}</p>
              </div>
            </v-card-text>

            <template v-else>
              <v-card-text>
                <h4 class="text-center primary--text">UPLOAD VIA FTP</h4>
                <p class="text-center">You can connect to the DOERS FTP servers as follows:</p>
                <p class="text-center">Server / Host <br> ftp://<span class="error--text">{{ ftp_host }}</span></p>
                <p class="text-center">Use your {{ app_name }} userId(<span class="error--text">{{ ftp_user }}</span>) and password to login to our server. Upload your images to this directory: <br> 
                  <span class="error--text">/{{ path }}/</span>
                </p>
                <p class="text-center">Please use a FTP client, such as Transmit,  FileZilla, Cyberduck or Fetch</p>
                <p class="text-center"><b>Please note:</b> only image formats such as TIFF, JPG, PSD, EPS and PNG can be uploaded. Files such as ZIP, RAR and RAW can not be uploaded.</p>
              </v-card-text>

              <v-card-actions class="text-center d-block">
                <v-btn small class="primary rounded-pill px-5" @click="refreshFtpUpload()">
                  REFRESH
                </v-btn>
              </v-card-actions>
            </template>
          </v-card>
        </div>
      </v-col>

      <v-col v-if="list.length>0" cols="12" sm="2">
        <p v-if="!isUploadMore" class="pt-5 primary--text">{{ (upload_method==1) ? "VIA THIS WEBSITE" : "VIA FTP" }}</p>
      </v-col>
      <!-- Image galary viewer -->
      <v-col v-if="list.length>0" cols="12" sm="10" offset-md="2">
        <v-row no-gutters class="gray-border px-1 pb-2 mb-1 rounded">
          <v-col cols="12" class="pa-3 ml-2">
            <v-row>
              <v-col v-if="isReviewer && status=='requested'" cols="12" sm="8" class="pa-0" align-self="center">
                <v-checkbox
                  v-model="selectAll"
                  label="SELECT ALL AS POSSIBLE"
                  class="ma-0 pa-0"
                  @click="selectAllAsPossible"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4" class="px-0">
                <h3 class="pa-0">
                  <span class="primary--text">IMAGES</span> {{ list.length }} IMAGES {{ getFileSize(list) }}
                </h3>
              </v-col>
            </v-row>
          </v-col>
          
          <template v-for="(item, index) in historyList">
            <v-col :key="index" cols="6" sm="2" class="pa-1">
              <v-card outlined tile class="pa-0 overflow-hidden">
                <v-skeleton-loader v-if="isLoading" type="image" class="image-style"></v-skeleton-loader>
                <div v-else>
                  <v-card-text class="text--primary text-center pa-0">
                    <v-checkbox v-if="isReviewer && status=='requested'"
                      v-model="item.selected"
                      :value="item.selected"
                      @click="updateSelected"
                      class="mt-0 pa-0 ml-1 inline"
                      style="margin-bottom:-28px;"
                      hide-details
                      tile
                    ></v-checkbox>
                    <v-tooltip top
                      v-if="item.instruction && item.hasOwnProperty('selected')"
                      :color="`${!item.selected ? 'error' : 'primary'}`"
                    >
                      <template v-slot:activator="{ on }">
                        <img v-on="on"
                          :class="`cursor-pointer image-style ${!item.selected ? 'image-opacity' : ''}`"
                          :src="`${$tools.getEnv('VUE_APP_FILE_SERVER')}${item.path}/${item.name}`"
                          @click="openGallery(index)"
                        />
                      </template>
                      <span v-if="item.instruction">{{item.instruction}}</span>
                      <span v-if="!item.selected"><br>Not Possible</span>
                    </v-tooltip>
                    <v-tooltip top v-else-if="item.hasOwnProperty('selected') && !item.selected"
                      :color="`${item.hasOwnProperty('selected') && !item.selected?'error':'primary'}`"
                    >
                      <template v-slot:activator="{ on }">
                        <img v-on="on"
                          :class="`cursor-pointer image-style ${item.hasOwnProperty('selected') && !item.selected?'image-opacity':''}`"
                          :src="`${$tools.getEnv('VUE_APP_FILE_SERVER')}${item.path}/${item.name}`"
                          @click="openGallery(index)"
                        />
                      </template>
                      <span>Not Possible</span>
                    </v-tooltip>
                    <img v-else
                      class="cursor-pointer image-style"
                      :src="`${$tools.getEnv('VUE_APP_FILE_SERVER')}${item.path}/${item.name}`"
                      @click="openGallery(index)"
                    />
                  </v-card-text>

                  <v-card-actions class="action-box d-block pa-1">
                    <span style="font-size:11px;">{{getImageName(item.name)}}</span>
                    <span class="float-right">
                      <v-tooltip v-if="item.instruction&&item.instruction!=null" top color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small color="primary" v-bind="attrs" v-on="on">{{mdiNotebookCheck}}</v-icon>
                        </template>
                        <span>{{item.instruction}}</span>
                      </v-tooltip>
                      <template v-if="['requested','created'].includes(status)">
                        <v-tooltip v-if="isClient" top color="warning">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small color="warning" @click="clickInstruction(item,index)" v-bind="attrs" v-on="on">{{mdiNotebookEdit}}</v-icon>
                          </template>
                          <span>Instruction</span>
                        </v-tooltip>
                        <v-icon v-if="isClient" small color="error" @click="clickDeleteFile(item)">{{mdiDelete}}</v-icon>
                      </template>
                    </span>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-responsive
              v-if="index === 5"
              :key="`width-${index}`"
              width="100%"
              class="pb-1"
            ></v-responsive>
          </template>
          <LightBox
            ref="lightbox"
            :nThumbs="pageSize"
            :media="media"
            :show-caption="true"
            :show-light-box="false"
          />
        </v-row>
        <v-pagination
          class="pagination mb-2"
          v-model="page"
          :length="pages"
          :showCaption="true"
          @input="updatePage">
        </v-pagination>
      </v-col>
    </v-row>

    <v-dialog v-model="editModal" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          Instruction as per image 
        </v-card-title>
        <v-card-text class="pt-5">
          <v-textarea v-model="instruction" label="Your text..." rows="3" solo :counter="100"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="error" class="rounded-pill px-5" text @click="editModal = false"> Cancel </v-btn>
          <v-btn small class="primary rounded-pill px-5" @click.stop="saveInstruction()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogModal
      v-model="showModal"
      title="Confirmation"
      :message="modal_msg"
      @onCancel="showModal = false"
      @onConfirmation="isConfirmDelete()"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mdiCloudUpload, mdiDelete, mdiNotebookEdit, mdiNotebookCheck, mdiCancel } from '@mdi/js'
import { SAVE_CONTENT, LOAD_ALL_CONTENT,EDIT_FILE_NAME, DELETE_FILE } from "@/store/_actiontypes";
import DialogModal from "@/components/common/DialogModal";
import LightBox from 'vue-image-lightbox'
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')


export default {
  components: {
    DialogModal,
    LightBox,
  },
  props: {
    isReviewer: {
      type: Boolean,
      default: false
    },
    isClient: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: "requested",
    },
    path: {
      type: String,
      default: "/contents/local/1",
    },
    method: {
      type: Number,
      default: 1,
    },
    contents: {
      type: Object,
      default(){
        return {}
      }
    },
    reviewed: {
      type: Number,
      default: 0,
    },
  },
  data(props){
    return {
      mdiNotebookEdit,
      mdiNotebookCheck,
      mdiDelete,
      mdiCloudUpload,
      mdiCancel,
      selectAll: false,
      isUploadMore: false,
      loadingBtn: false,
      isLoading: false,
      isBrowsed: false,
      onUpload: false,
      progressInfos: [],
      deleteFile: {},
      showModal: false,
      editModal: false,
      filetxt: "",
      modal_msg: "",
      page: 1,
			pageSize: 12,
			listCount: 0,
      historyKey: 0,
			historyList: [],
			instruction: "",
      media: [],
      reviewCheck: [],
      content_type: "quotation",
      upload_method: props.method,
      content_path: props.path,
      app_name: "DoersClipping",
      ftp_host: "23.227.167.229",
      ftp_user: "user",
      list: this.setContent(props.contents)
    }
  },
	computed: {
		pages(){
			if (this.pageSize == null || this.listCount == null) return 0;
			return Math.ceil(this.listCount / this.pageSize);
		}
	},
	created(){
    this.app_name = this.$tools.getEnv('VUE_APP_NAME');
    this.ftp_user = this.$tools.getFtpUser();
    this.ftp_host = this.$tools.getEnv('VUE_APP_FTP_SERVER');

    this.initPage();
    this.updatePage(this.page);
    this.setData();
	},
  methods: {
    ...mapActions("upload", [SAVE_CONTENT, LOAD_ALL_CONTENT, EDIT_FILE_NAME, DELETE_FILE]),

    setContent(contents){
      let data = [];
      if(contents.hasOwnProperty(this.status)){
        data = contents[this.status];
      }
      return data;
    },
    upload(idx, file){
      this.loadingBtn = true;
      this.progressInfos[idx] = { percentage: 0, fileName: file.name };

      this.SAVE_CONTENT({
        save_to: "local",
        path: "/contents/save/" + this.content_path,
        content_type: this.content_type,
        formData: file,
        onUploadProgress: (event) => {
          this.progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
        },
      }).then((res) => {
        if(res.status==200){
          this.loadingBtn = false;
          let data = res.data.content.uploaded;
          this.list.push(data[0]);

          this.getFileSize(data);
          this.initPage();
          this.updatePage(this.page);
          this.setData();
        }
      }).catch(() => {
        this.loadingBtn = false;
        this.progressInfos[idx].percentage = 0;
      });
    },
    refreshFtpUpload(){
      this.LOAD_ALL_CONTENT({
        get_from: "local",
        path: this.content_path,
        content_type: this.content_type
      }).then((res) => {
        if(res.status==201){
          this.list = res.data.content;

          this.getFileSize(this.list);
          this.updatePage(this.page);
          this.initPage();
          this.setData();
        }
      }).catch(() => {});
    },
    uploadviaweb(){
      this.onCancel();
      this.isUploadMore = true;
      let selectedFiles = this.$refs.uploader.files;

      for(let i = 0; i < selectedFiles.length; i++){
        this.upload(i, selectedFiles[i]);
      }
      this.$emit('onClick', 'uploading');
    },
    onCancel(){
      this.filetxt = "";
      this.onUpload = false;
      this.isBrowsed = false;
      this.loadingBtn = false;
      this.progressInfos = [];
    },
    uploadMore(val){
      this.isUploadMore = val;
      this.setData();
    },
    onChange(val){
      this.upload_method = val;
      this.setData();
    },
    selectFiles(files){
      this.isBrowsed = true;
      var filetxt = "";
      for(var i = 0; i < Object.keys(files).length; i++){
        if(files[i].size > 10000000){
          this.onCancel();
          alert(files[0].name +' is greater than 10MB');
          return false;
        };
        let size = this.$tools.getFileSize(files[i].size);
        filetxt = filetxt + files[i].name + ` (${size})\n`;
      }
      if(filetxt != ""){
        this.onUpload = true;
        this.filetxt = filetxt;
      }
    },
		initPage(){
			this.listCount = this.list.length;
			if(this.listCount < this.pageSize){
				this.historyList = this.list;
			} else{
				this.historyList = this.list.slice(0, this.pageSize);
			}
		},
		updatePage(pageIndex){
			this.page = pageIndex;
			let _start = (pageIndex - 1) * this.pageSize;
			let _end = pageIndex * this.pageSize;
			this.historyList = this.list.slice(_start, _end);

      let baseurl = this.$tools.getEnv('VUE_APP_FILE_SERVER');
      let media = [];
      this.historyList.forEach(item => {
        let media_obj = {}
        media_obj["type"] = "image";
        media_obj["caption"] = item.name;
        media_obj["thumb"] = `${baseurl}${item.path}/${item.name}`;
        media_obj["src"] = `${baseurl}${item.path}/${item.name}`;
        media.push(media_obj);
      });
      this.media = media;
		},
    openGallery(index){
      this.$refs.lightbox.showImage(index)
    },
    getFileSize(){
      let size = 0;
      if(this.list.length > 0){
        for(var i = 0; i < this.list.length; i++){
          size = size + this.list[i].size;
        }
      }
      return this.$tools.getFileSize(size);
    },
    clickDeleteFile(file){
      this.modal_msg = "Are you sure to remove "+ file.name +" ?";
      this.deleteFile = file;
      this.showModal = true;
    },
    isConfirmDelete(){
      this.isLoading = true;
      this.DELETE_FILE({
        from: "local",
        name: this.deleteFile.name,
        path: this.content_path,
        content_type: this.content_type
      }).then((res) => {
        if(res.status == 200){
          this.list = res.data.content;
          this.initPage();
          this.updatePage(this.page);
          this.setData();
          this.showModal = false;
          this.isLoading = false;
        }
      }).catch((err) => {
        this.showModal = false;
        this.isLoading = false;
      });
    },
    clickInstruction(data, index){
      this.historyKey = index;
      this.instruction = data.instruction;
      this.editModal = true;
    },
    saveInstruction(){
      let history = this.historyList[this.historyKey];
      history.instruction = this.instruction.substring(0,80);;
      this.editModal = false;
      this.setData();
    },
    setData(){
      let data = {
        method: this.upload_method,
        isUploaded: this.list.length>0 ? true : false,
        upload_more: this.isUploadMore,
        file_info: this.updateInstructions(this.list)
      }
      this.$emit("getData", data);
    },
    updateInstructions(data){
      let instructions = [];
      data.forEach(item => {
        let file_info = {}
        file_info[item.name] = item.instruction;
        instructions.push(file_info);
      });
      return instructions;
    },
    selectAllAsPossible(){
      this.list.forEach(item => {
        item["selected"] = this.selectAll
      });
    },
    updateSelected(){
      this.updatePage(this.page)
    },
    getImageName(name){
      if(this.isClient && (this.status=='requested'||this.status=='created')){
        name = name.substring(0, 9) + "..";
      } else if(name.length <= 15){
        name = name
      } else{
        name = name.substring(0, 13) + "..";
      }
      return name;
    }

  }
}
</script>

<style scoped>
.gray-border{
  border:1px solid gray;
}
.action-box{
  height: 25px;
  font-size: 12px;
  background: #fafafa;
}
.image-style{
  width:120px;
  height:120px;
  margin-bottom:-5px;
}
.image-style:hover{
  cursor: pointer;
}
.image-opacity {
  opacity: 0.5;
}
</style>
